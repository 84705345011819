<template>
  <v-container fluid>
      <!-- <global-filterMenuNew disableTerminals /> -->
    <v-row>
      <global-EventFilterMenuProductSales header='Sales'/>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-card
          dark
          outlined
        >
          <v-card-title class="secondary py-1 text-subtitle-1">
            <v-icon left dense> mdi-chart-pie </v-icon>
            Total Transactions Volume
          </v-card-title>
          <v-card-text >
            <v-row>
              <v-col lg="6" md="12" sm="6" cols="12">
                <v-skeleton-loader
                  elevation="2"
                  type="sentences@6"
                  :loading="productSalesTotalTransactionsLoading"
                >
                <v-card
                  class="primary mb-2"
                  dark
                >
                  <v-card-text class="headline font-weight-bold py-0" style="border: 0px solid red">
                    <div class="subtitle-1"> Total Transactions: </div>
                    <!-- 76714 -->
                    {{productSalesTotalSalesData.TotalTransactions}}
                  </v-card-text>
                </v-card>

                
                <v-card
                  class="primary mb-2"
                  dark
                >
                  <v-card-text class="headline font-weight-bold py-0" style="border: 0px solid red">
                    <div class="subtitle-1"> Cash: </div>
                    <!-- 2159 -->
                    {{productSalesTotalSalesData.TotalTransactionsCash}}
                    
                  </v-card-text>
                </v-card>
                
                <v-card
                  class="primary mb-2"
                  dark
                >
                  <v-card-text class="headline font-weight-bold py-0" style="border: 0px solid red">
                    <div class="subtitle-1"> Card: </div>
                    <!-- 74262 -->
                    {{productSalesTotalSalesData.TotalTransactionsCard}}
                  </v-card-text>
                </v-card>
                
                <v-card
                  class="primary mb-2"
                  dark
                >
                  <v-card-text class="headline font-weight-bold py-0" style="border: 0px solid red">
                    <div class="subtitle-1"> Voucher: </div>
                    <!-- 293 -->
                    {{productSalesTotalSalesData.TotalTransactionsVoucher}}
                  </v-card-text>
                </v-card>
                
                <v-card
                  class="primary mb-2"
                  dark
                >
                  <v-card-text class="headline font-weight-bold py-0" style="border: 0px solid red">
                    <div class="subtitle-1"> Total Products: </div>
                    <!-- 151132 -->
                    {{productSalesTotalSalesData.Quantity}}
                  </v-card-text>
                </v-card>

                <v-card
                  class="primary mb-2"
                  dark
                >
                  <v-card-text class="headline font-weight-bold py-0" style="border: 0px solid red">
                    <div class="subtitle-1"> Products Per head </div>
                    <!-- 1.61 -->
                    {{productSalesTotalSalesData.Productsperhead}}
                  </v-card-text>
                </v-card>
                </v-skeleton-loader>
                
              </v-col>
              <v-col lg="6" md="12" sm="6" cols="12">
                <skeletons-graph :loading="productSalesTotalTransactionsLoading">
                  <charts-pie :chartdata="totalTransactionsDonutChartData" :options="options" />
                </skeletons-graph>
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-card
          dark
          outlined
        >
          <v-card-title class="secondary py-1 text-subtitle-1">
            <v-icon left dense> mdi-chart-pie </v-icon>
            Transactions Volume By Stall
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col lg="12" md="12" sm="12" cols="12" style="padding-bottom: 20px !important">
                <skeletons-graph :loading="productSalesTotalTransactionsLoading">
              <charts-pie :chartdata="transactionsStallComparisonDonutChartData" :options="options" />
            </skeletons-graph>
              </v-col>
              
            </v-row>
          </v-card-text>

          <!-- <v-card-text v-else>
            <label>No data available</label>
          </v-card-text> -->
        </v-card>
      </v-col>
    </v-row>
    
    <v-row>
      
      <v-col
        cols="12"
      >
        <v-card
          dark
          outlined
        >
          <v-card-title class="secondary py-1 text-subtitle-1">
            <v-icon left dense> mdi-chart-line </v-icon>
            Total Transactions Timeline
          </v-card-title>
          <v-card-text>
            <skeletons-graph :loading="productSalesTotalTransactionsLoading">
              <charts-line :chartdata="stallComparisonTimelineChartData" :options="options" />
            </skeletons-graph>
          </v-card-text>
        </v-card>
      </v-col>
      
    </v-row>
    
    <v-row>        
      <v-col cols="12">
        <v-card
          dark
          outlined
        >
          <v-card-title class="secondary py-1 text-subtitle-1">
            <v-icon left dense> mdi-file-table </v-icon>
            Product Sales Table
          </v-card-title>
          <v-card-text class="pa-0">
            <v-data-table
              v-model="selectedTransaction"
              :headers="headers"
              :items="stallTableData"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          dark
          outlined
        >
          <v-card-title class="secondary py-1 text-subtitle-1">
            <v-icon left dense> mdi-file-table </v-icon>
            Product Tags Table
          </v-card-title>
          <v-card-text class="pa-0">
            <v-data-table
              v-model="selectedTransaction"
              :headers="headersOfTagTable"
              :items="productsTagsData"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({

    productSalesTotalSalesData: {
      "TotalTransactions": 0,
      "TotalTransactionsCash": 0,
      "TotalTransactionsCard": 0,
      "TotalTransactionsVoucher": 0,
      "Quantity": 0,
      "Productsperhead": 0
    },

    totalTransactionsDonutChartData: {
        labels: ["No Data Available"],
        datasets: [
          {
            backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
            data: [1],
            hoverBorderWidth: 3
          }
        ]
    },

    transactionsStallComparisonDonutChartData: {
      labels: ["No Data Available"],
      datasets: [
        {
          backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
          data: [1],
          hoverBorderWidth: 3
        }
      ]
    },

    stallComparisonTimelineChartData: {
      labels: ["No Data Available"],
      datasets: [
        {
          label: 'No Data Available',
          borderColor: "#FF0E00",
          pointBackgroundColor: 'white',
          borderWidth: 1,
          pointBorderColor: "#FF0E00",
          backgroundColor: 'transparent',
          data: [
            0
          ]
        }
      ]
    },

    stallTableData: [],

    productsTagsData: [],
    
    selectedTransaction: {},
    headers: [
      { text: "Rank", value: "Rank"},
      { text: "Product Name", value: "Product Name"},
      { text: "Quantity", value: "Quantity"},
      { text: "Gross Sales", value: "Gross Sales"},
      { text: "Net Sales", value: "Net Sales"}
    ],

    headersOfTagTable: [
      { text: "Rank", value: "Rank"},
      { text: "Tag name", value: "Product Name"},
      { text: "Quantity", value: "Quantity"},
      { text: "Gross Sales", value: "Gross Sales"},
      { text: "Net Sales", value: "Net Sales"}
    ],

    // chartData: {
    //   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    //   datasets: [
    //     {
    //       label: 'Prices',
    //       borderColor: '#249EBF',
    //       pointBackgroundColor: 'white',
    //       borderWidth: 1,
    //       pointBorderColor: '#249EBF',
    //       backgroundColor: 'transparent',
    //       data: [5, 10, 15, 25, 45, 70, 115, 185, 70, 75, 70, 60]
    //     }
    //   ]
    // },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        labels: {
            fontColor: "white"
        }
      },
      tooltips: {
        callbacks: {
            label(tooltipItem, data) {
              const totalValue = data.datasets[0].data.reduce((acc, value) => acc + parseInt(value), 0);
              const currentValue = parseInt(data.datasets[0].data[tooltipItem.index])
              const percentage = (currentValue/totalValue * 100).toFixed(2)
              return `${data.labels[tooltipItem.index]}: ${data.datasets[0].data[tooltipItem.index]} (${percentage})%`;
            }
        }
      }
    },
    // pieChartData: {
    //   labels: [
    //     'Red',
    //     'Blue',
    //     'Yellow'
    //   ],
    //   datasets: [{
    //     label: 'My First Dataset',
    //     data: [300, 50, 100],
    //     backgroundColor: [
    //       'rgb(255, 99, 132)',
    //       'rgb(54, 162, 235)',
    //       'rgb(255, 205, 86)'
    //     ],
    //     hoverOffset: 10,
    //     hoverBorderWidth: 5
    //   }],
    // },

    // loading
    timelineLoading: true,
  }),

  created() {

    var self = this
      setTimeout(
        function() {
          self.timelineLoading = false
        }, 2000);
    },
    filters: {
      currency(value) {
        var formatter = new Intl.NumberFormat('en-AU',{
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
        return '$' + formatter.format(value);
      },
      number(value) {
        var formatter = new Intl.NumberFormat('en-AU',{
          maximumFractionDigits: 0
        })
        return formatter.format(value);
      }
    },

  computed: {
    ...mapState('dashboard', ['productSalesTotalTransactions', 'productSalesTotalTransactionsLoading']),
  },

  methods: {

    // ProcessProductSalesTotalTransactionData
    ProcessProductSalesTotalTransactionData()  {
      if (this.productSalesTotalTransactions.total_sales_data) {
        if(this.productSalesTotalTransactions.total_sales_data.TotalTransactions) {
          this.productSalesTotalSalesData.TotalTransactions = this.$options.filters.number(this.productSalesTotalTransactions.total_sales_data.TotalTransactions)
        } else {this.productSalesTotalSalesData.TotalTransactions = 0}

        if(this.productSalesTotalTransactions.total_sales_data.TotalTransactionsCash) {
          this.productSalesTotalSalesData.TotalTransactionsCash = this.$options.filters.number(this.productSalesTotalTransactions.total_sales_data.TotalTransactionsCash)
        } else {this.productSalesTotalSalesData.TotalTransactionsCash = 0}

        if(this.productSalesTotalTransactions.total_sales_data.TotalTransactionsCard) {
          this.productSalesTotalSalesData.TotalTransactionsCard = this.$options.filters.number(this.productSalesTotalTransactions.total_sales_data.TotalTransactionsCard)
        } else {this.productSalesTotalSalesData.TotalTransactionsCard = 0}

        if(this.productSalesTotalTransactions.total_sales_data.TotalTransactionsVoucher) {
          this.productSalesTotalSalesData.TotalTransactionsVoucher = this.$options.filters.number(this.productSalesTotalTransactions.total_sales_data.TotalTransactionsVoucher)
        } else {this.productSalesTotalSalesData.TotalTransactionsVoucher = 0}

        if(this.productSalesTotalTransactions.total_sales_data.Quantity) {
          this.productSalesTotalSalesData.Quantity = this.$options.filters.number(this.productSalesTotalTransactions.total_sales_data.Quantity)
        } else {this.productSalesTotalSalesData.Quantity = 0}

        if(this.productSalesTotalTransactions.total_sales_data.Productsperhead) {
          this.productSalesTotalSalesData.Productsperhead = this.productSalesTotalTransactions.total_sales_data.Productsperhead
        } else {this.productSalesTotalSalesData.Productsperhead = 0}
      }
    },

    // ProcessProductSalesTotalTransactionDataPie
    ProcessProductSalesTotalTransactionDataPie()  {
      if (this.productSalesTotalTransactions.stall_comparison_data.length > 0) {
        this.totalTransactionsDonutChartData = {
          labels: this.productSalesTotalTransactions.stall_comparison_data.map(x => x['Stall Name']),
          datasets: [
            {
            backgroundColor: this.$generateColors.getColors(this.productSalesTotalTransactions.stall_comparison_data.length),//['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
            data: this.productSalesTotalTransactions.stall_comparison_data.map(x => x.Sales),//[this.productSalesTotalTransactions.cash, this.productSalesTotalTransactions.card, this.productSalesTotalTransactions.voucher],
            hoverBorderWidth: 3
            }
          ]
        }
      } else {
        this.totalTransactionsDonutChartData = {
          labels: ['No data available'],
          datasets: [
            {
              backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
              data: [1],
              hoverBorderWidth: 3
            }
          ]
        }
      }
    },

    // ProcessProductSalesStallComparisonDonutDataPie
    ProcessProductSalesStallComparisonDonutDataPie()  {
      if (this.productSalesTotalTransactions.stall_comparison_data.length > 0) {
        this.transactionsStallComparisonDonutChartData = {
          labels: this.productSalesTotalTransactions.stall_comparison_data.map(x => x['Stall Name']),
          datasets: [
            {
              backgroundColor: this.$generateColors.getColors(this.productSalesTotalTransactions.stall_comparison_data.length),//['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],,
              data: this.productSalesTotalTransactions.stall_comparison_data.map(x => x.Sales),
              hoverBorderWidth: 3
            }
          ]
        }

      } else {
        this.transactionsStallComparisonDonutChartData = {
          labels: ["No Data Available"],
          datasets: [
            {
              backgroundColor: ['rgb(255, 99, 132)'],//['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],,
              data: [1],
              hoverBorderWidth: 3
            }
          ]
        }
      }
    },

    // ProcessStallComparisonTimelineChartData
    ProcessStallComparisonTimelineChartData()  {
      if (this.productSalesTotalTransactions.stall_comparison_timeline) {
        this.stallComparisonTimelineChartData = {
          labels: this.productSalesTotalTransactions.stall_comparison_timeline[0].Stalls.map(obj=> {
                  return obj.Timestamp
                }),
          datasets: this.productSalesTotalTransactions.stall_comparison_timeline.map((obj, index) => {
            obj.label = obj['Stall Name']
            obj.borderColor = index === 0 ? '#FF0E00' : index === 1 ? '#649EB9' : 'blue',
            obj.pointBackgroundColor = 'white',
            obj.borderWidth = 1,
            obj.pointBorderColor = index === 0 ? '#FF0E00' : index === 1 ? '#649EB9' : 'blue',
            obj.backgroundColor = 'transparent',
            obj.data = obj.Stalls.map(obj=> {
                        return obj.SalesAmount
                      })
            return obj;
          })
        }
      }
    },

    // ProcessStallTableData
    ProcessStallTableData()  {
      if (this.productSalesTotalTransactions) {
        this.stallTableData = 
          this.productSalesTotalTransactions.products_table_data.map(item => ({
            'Rank': item['Rank'],
            'Product Name': item['Product Name'],
            'Quantity': item['Quantity'] !== undefined ? this.$options.filters.number(item['Quantity']) : 'N/A',
            'Net Sales': item['Net Sales'] !== undefined ? this.$options.filters.currency(item['Net Sales']) : 'N/A',
            'Gross Sales': item['Gross Sales'] !== undefined ? this.$options.filters.currency(item['Gross Sales']) : 'N/A'
            }))
      } else {
        this.stallTableData = []
      }
    },

  },

  watch:{
    
    'productSalesTotalTransactions.total_sales_data': {
    handler() {
      this.ProcessProductSalesTotalTransactionData();
      this.ProcessProductSalesTotalTransactionDataPie();      
    },
    immediate: true
    },
    
    'productSalesTotalTransactions.stall_comparison_data': {
    handler() {
      this.ProcessProductSalesStallComparisonDonutDataPie();      
    },
    immediate: true
    },
    
    'productSalesTotalTransactions.stall_comparison_timeline': {
    handler() {
      if (this.productSalesTotalTransactions.stall_comparison_timeline.length > 0) {
        this.ProcessStallComparisonTimelineChartData();
      } else {
        this.stallComparisonTimelineChartData = {
          labels: ["No Data Available"],
          datasets: [
            {
              label: 'No Data Available',
              borderColor: "#FF0E00",
              pointBackgroundColor: 'white',
              borderWidth: 1,
              pointBorderColor: "#FF0E00",
              backgroundColor: 'transparent',
              data: [0]
            }
          ]
        }
        
      }
    },
    immediate: true
    },
    
    'productSalesTotalTransactions.products_table_data': {
    handler() {
      if (this.productSalesTotalTransactions.products_table_data.length > 0) {
        this.ProcessStallTableData();
      } else {
        this.stallTableData = []
      }            
    },
    immediate: true
    },

    

  }
}


</script>


<style scoped>
h1{
  color: #a09c94;
  font-family: sans-serif;
}
</style>